<template>
    <div>
        <!--  Page Header  -->
        <div class="page-header d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <router-link :to="{ name: 'resource_center' }">
                    <button class="btn btn-control">
                        <img alt="Return back" src="@/assets/icons/bold-icon previous.svg">
                    </button>
                </router-link>
                <h3 class="page-header-title ms-2">Yard Sign</h3>
                <span class="subtitle">Create your custom yard signs</span>
            </div>
            <base-button title="Download" action="primary" :loading="ui.generating" @click-btn="savePdf" />
        </div>

        <!-- Page Content -->
        <div class="page-content">
            <div v-if="ui.loading" class="text-center mt-5">
                <div class="spinner-border mt-5" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-lg-8 order-2 order-lg-1 generator-col border-right-light">
                    <pdf :src="src" :page="1" class="page-preview"/>
                </div>
                <div class="col-lg-4 order-1 order-lg-2 generator-col">
                    <div class="row">
                        <div class="col-12">
                            <div class="generator-col__title">
                                Card Details
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="form-group">
                                <label for="nameField">Name</label>
                                <input  id="nameField" @change="updatePreview" v-model="formData.name"
                                        class="form-control" minlength="3" type="text">
                            </div>
                        </div>
                        <div class="col-7 mt-3">
                            <div class="form-group">
                                <label for="phoneNumberField">Phone Number</label>
                                <input  id="phoneNumberField" @change="updatePreview" v-model="formData.phone"
                                        v-mask="'###-###-####'" class="form-control" minlength="3" type="text">
                            </div>
                        </div>
                        <div class="col-4"></div>
                        <div class="col-4 mt-3">
                            <div class="form-group">
                                <label for="dreField">DRE #</label>
                                <input  id="dreField" v-model="formData.dre" @change="updatePreview"
                                        class="form-control" minlength="3" type="number">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pdf from 'vue-pdf'
import BaseButton from '../../components/BaseButton.vue'

export default {
    name: 'YardSign',
    components: { pdf, BaseButton },
    data() {
        return {
            formData: {
                name: this.$store.getters.getFullName,
                phone: this.$store.getters.getPhone,
                dre: this.$store.getters.getDre == 'undefined' ? this.$store.getters.getDre : '',
                isDownload: 0
            },
            ui: {
                loading: false,
                generating: false
            },
            src: null,
            baseUrl: process.env.VUE_APP_API_URL + '/api/v1/yard-sign/download',
        }
    },
    methods: {
        savePdf() {
            this.ui.generating = true
            this.formData.isDownload = 1
            this.$http.get(`/api/v1/yard-sign/download`, {
                params: this.formData,
                responseType: 'arraybuffer'
            }).then(res => {
                this.generateFile(res);
            }, err => {
                console.log(err)
                this.pushAlert('error', 'Failed Generate')
                this.ui.generating = false
             })
        },
        updatePreview() {
            this.src = pdf.createLoadingTask(this.baseUrl + `?id=${this.$store.getters.getUserId}&name=${this.formData.name}&dre=${this.formData.dre}&phone=${this.formData.phone}`)
        },
        generateFile(res) {
            let fileURL = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'})),
                fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'yard_sign.pdf');

            document.body.appendChild(fileLink);
            fileLink.click();
            this.ui.generating = false
        }
    },
    mounted() {
        this.ui.loading = true

        this.src = pdf.createLoadingTask(this.baseUrl + `?id=${this.$store.getters.getUserId}&name=${this.formData.name}&position=${this.formData.position}&dre=${this.formData.dre}&phone=${this.formData.phone}&email=${this.formData.email}`)
        this.src.promise.then(() => {
            this.ui.loading = false
        })
    },
}
</script>

<style lang="scss" scoped>

.page-header {
    button {
        &.btn-primary {
            min-width: 120px;
        }
    }
}

.page-content {
    .generator-col {
        padding: 24px 30px;
        max-height: 100vh;
        &__title {
            color: #000000;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 21px;
            margin-bottom: 24px;
        }

        label {
        color: rgba(0, 0, 0, .7);
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
        }
    }
    .form-group {
        input {
            &.form-control {
                max-height: 36px;
                height: 36px;
                min-height: 36px;
                border: 1px solid #B4B4B4;
                border-radius: 4px;
                background-color: #FFFFFF;
                color: #000000;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px;
            }
    }
  }
}
.page-preview {
  display: inline-block;
  width: 100%;
  border: #0a0a0a 1px solid;
  margin-bottom: 30px;

  .annotationLayer {
      display: none;
  }
}
</style>
